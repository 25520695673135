<template>
  <ion-header>
    <ion-toolbar mode="md">
      <ion-buttons slot="start">
        <ion-back-button mode="md" default-href="/feed" @click="closeModal" />
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button @click="closeModal">
          <ion-icon slot="icon-only" :icon="icons.close" />
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="video-block">
      <media-video
        :id="video.key.split('~')[1]"
        :name="video.name"
        :url="video.apiUrl"
        :image="video.videoPreview?.url ?? ''"
        :mime-type="video.mimeType"
        :size="size"
      />
    </div>
  </ion-content>
</template>

<script lang="ts" setup>
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonBackButton,
  modalController,
} from '@ionic/vue';
import { useWindowSize } from '@vueuse/core';
import { closeOutline } from 'ionicons/icons';
import type { PropType } from 'vue';
import { reactive } from 'vue';

import type { FileModel } from '@/@types';
import { MediaVideo } from '@/components';

defineProps({
  video: {
    type: Object as PropType<FileModel>,
    required: true,
  },
});

const { width: innerWidth } = useWindowSize();

const size = reactive({
  width: innerWidth.value,
  height: (innerWidth.value * 9) / 16,
}) as { width: number; height: number };
const icons = { close: closeOutline };

const closeModal = () => {
  modalController.dismiss();
};
</script>

<style>
.video-block {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
