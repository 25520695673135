import type { ResponseErrorModel, ResponseSearchModel } from '@/@types';
import axios from '@/services/axios';

export class SearchApiService {
  async search(query: {}): Promise<ResponseSearchModel | ResponseErrorModel> {
    return axios.get('/search/all', { params: query });
  }

  async suggest(query: {}): Promise<ResponseSearchModel | ResponseErrorModel> {
    return axios.get('/search/suggest', { params: query });
  }
}
