<template>
  <div :class="['card-date', { danger: DateHelper.isDateInPast(date) }]">
    <ion-icon :icon="icons.calendar" />
    <ion-label>{{ formatDateHelper(date, 'year') }}</ion-label>
  </div>
</template>

<script lang="ts" setup>
import { IonLabel, IonIcon } from '@ionic/vue';
import { calendarOutline } from 'ionicons/icons';

import { DateHelper, formatDateHelper } from '@/helpers';

defineProps({
  date: {
    type: String,
    required: true,
  },
});
const icons = {
  calendar: calendarOutline,
};
</script>

<style scoped lang="scss">
.card-date {
  display: flex;
  align-items: center;
  ion-icon {
    margin-right: calc(app-padding(lg) / 4);
  }
  &.danger {
    color: var(--ion-color-danger) !important;
  }
}
</style>
