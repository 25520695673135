interface IImages {
  storeImage: (key: string, blob: Blob) => void;
  giveImage: (key: string) => Blob | null;
  getStoredImagesInfo: () => { count: number; size: number };
  clearImages: () => void;
}

let instance: IImages | null = null;

export function useImages(): IImages {
  if (instance) {
    return instance;
  }

  const images: Record<string, Blob> = {};

  const storeImage = (key: string, blob: Blob): void => {
    images[key] = blob;
  };

  const giveImage = (key: string): Blob | null => {
    return images[key] || null;
  };

  const clearImages = (): void => {
    Object.keys(images).forEach((key) => {
      delete images[key];
    });
  };

  const getStoredImagesInfo = (): { count: number; size: number } => {
    const keys = Object.keys(images);
    const count = keys.length;
    const size = keys.reduce((total, key) => total + images[key].size, 0);
    return { count, size };
  };

  instance = {
    storeImage,
    giveImage,
    clearImages,
    getStoredImagesInfo,
  };

  return instance;
}
