<template>
  <span class="expand" @click.stop="onShow()" v-html="expandText" />
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { useI18n } from '@/i18n';

const { t } = useI18n();

const expandText: ComputedRef<string> = computed(() => {
  return t('conversations.expand');
});

const emit = defineEmits(['onShow']);

const onShow = () => {
  emit('onShow');
};
</script>
<style scoped lang="scss">
.expand {
  color: var(--ion-color-primary);
  white-space: nowrap;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
</style>
