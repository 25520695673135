import type { EventBusKey, UseEventBusReturn } from '@vueuse/core';

export const busKey: EventBusKey<'event-bus-event'> = Symbol('bus-key');

export const listener = (
  eventBus: UseEventBusReturn<any, any>,
  handler = (event: string) => {
    console.log(`${event}`);
  }
): void => {
  eventBus.on(handler);
};

export const unsubscribe = (
  eventBus: UseEventBusReturn<any, any>,
  handler = (event: string) => {
    console.log(`${event}`);
  }
): void => {
  eventBus.off(handler);
};

export const reset = (eventBus: UseEventBusReturn<any, any>): void => {
  eventBus.reset();
};
